.new-context-menu-popup {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    position: fixed;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #fff;
    border-radius: 2px;
    li {
        margin: 0;
        padding: 0;
    }
    .context-menu-item {
        cursor: pointer;
        &:hover {
            background-color: #fafafa;
        }
        .context-menu-content {
            padding: 5px 12px;
            display: block;
            span {
                margin-right: 15px;
            }
        }
    }
}
