.ant-table-wrapper {
    .ant-table-pagination.ant-pagination {
        margin-bottom: 0 !important;
    }
    .ant-table-thead {
        user-select: none;
    }
}

.ant-form {
    &.small-form {
        .ant-form-item {
            margin-bottom: 16px;
        }
        .ant-form-item-label {
            padding-bottom: 0;
        }
    }
}

.user-select-none {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.ant-tabs {
    .ant-tabs-tab {
        padding: 4px 0;
        + .ant-tabs-tab {
            margin-left: 20px;
        }
    }
}